<template>
  <v-container fluid>
    <v-slide-x-transition mode="out-in">
      <div
        v-if="selectedView === '1'"
        key="1"
        class="pa-5"
      >
        <v-flex class="mt-5">
          <h1 class="mb-6">
            Eshop Orders
          </h1>
          <v-spacer />
        </v-flex>
        <v-card elevation="0" rounded="xl">
          <v-card-title>
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="40"
              />
            </template>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="filteredItems"
            :search="search"
            :footer-props="footerProps"
            :loading="showProgress"
            loading-text="Loading... Please wait"
            :item-class="itemRowBackground"
          >
            <template v-slot:top>
              <v-row>
                <v-select
                  v-model="orderStatusSelectorSelection"
                  style="max-width: 300px;"
                  class="pl-5"
                  :items="selectStatusOfOrder"
                  label="Filter Order"
                /> <span class="text-h4 pl-4">{{ selectStatusOfOrder.find(item => item.value === orderStatusSelectorSelection) ? selectStatusOfOrder.find(item => item.value === orderStatusSelectorSelection).text : 'Orders' }}</span>
                <v-spacer />
                <v-text-field
                  v-model="search"
                  class="pa-4"
                  style="max-width: 600px;"
                  prepend-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                />
              </v-row>
            </template>
            <template v-slot:item.orderId="{ item }">
              <v-btn
                :color="statusColor(item.orderStatus)"
                dark
                @click="openOrderDetail(item)"
              >
                {{ item.orderId }}
              </v-btn>
            </template>
            <template v-slot:item.customerName="{ item }">
              <!-- <v-chip>
                {{ item.totalPaid }}kr
              </v-chip> -->
              {{ item.customerName }}<br> {{ item.customerEmail }}
            </template>
            <template v-slot:item.createdAt="{ item }">
              {{ dayjs(item.createdAt).format('dddd DD.MMMM YYYY') }}
            </template>
            <template v-slot:item.paymentStatus="{ item }">
              <v-chip v-if="item.paymentStatus === 'Unpaid'" color="red lighten-4">
                <v-icon
                  color="red"
                >
                  mdi-close
                </v-icon>
                <span class="red--text">Unpaid</span>
              </v-chip>

              <v-chip v-else-if="item.paymentStatus === 'Paid'" color="green lighten-4">
                <v-icon
                  color="green"
                >
                  mdi-check
                </v-icon>
                <span class="green--text">Paid</span>
              </v-chip>
            </template>
            <template v-slot:item.orderStatus="{ item }">
              <v-chip
                :color="statusColor(item.orderStatus)"
                dark
              >
                {{ item.orderStatus }}
              </v-chip>
            </template>
            <template v-slot:item.rmaNumber="{ item }">
              <v-chip
                v-if="item.rmaNumber"
                :color="item.orderStatus === 'Returned' ? 'red lighten-2' :'black'"
                dark
              >
                {{ item.rmaNumber }}
              </v-chip>
              <v-icon
                v-else
              >
                mdi-close
              </v-icon>
            </template>
            <template v-slot:item.totalPaid="{ item }">
              <v-chip>
                {{ item.totalPaid }}kr
              </v-chip>
            </template>
            <template v-slot:item.shipToAddress="{ item }">
              <div color="orange" dark>
                <div>{{ item.shipToAddress.street }}</div>
                <div>{{ item.shipToAddress.city }}</div>
                <div>{{ item.shipToAddress.postCode }}</div>
              </div>
            </template>
            <template v-slot:item.shippingMethod="{ item }">
              <div color="orange" dark>
                <div>{{ item.shippingMethod.type }}</div>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <div
        v-else-if="selectedView === '2'"
        key="2"
        class="pa-5"
      >
        <div>
          <v-btn
            color="orange"
            dark
            class="float-right"
            @click="goBack"
          >
            Back
          </v-btn>
        </div>
        <v-row class="mt-10">
          <v-col style="border: 1px solid lightgray; border-radius: 5px;" class="ma-3">
            <v-row>
              <v-col style="padding-bottom: 0; padding-top: 5px;;" cols="4">
                <span style="font-size: 20px; font-weight: bold;">OrderId:</span>
              </v-col>
              <v-col style="padding-bottom: 0; padding-top: 10px;">
                {{ orderDetail.orderId }}
              </v-col>
            </v-row>
            <v-row>
              <v-col style="padding-bottom: 0; padding-top: 0;" cols="4">
                <span style="font-size: 20px; font-weight: bold;">Order Status:</span>
              </v-col>
              <v-col style="padding-bottom: 0; padding-top: 5px; font-weight: 900;">
                {{ orderDetail.orderStatus }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" style="padding-bottom: 0; padding-top: 0;">
                <span style="font-size: 20px; font-weight: bold;">Payment Status:</span>
              </v-col>
              <v-col style="padding-bottom: 0; padding-top: 5px;">
                <span :style="orderDetail.paymentStatus === 'Paid' ? 'color: green; font-weight: 900;' : 'color: red; font-weight: 900;'">{{ orderDetail.paymentStatus }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" style="padding-bottom: 0; padding-top: 0;">
                <span style="font-size: 20px; font-weight: bold;">Delivery type:</span>
              </v-col>
              <v-col style="padding-bottom: 0; padding-top: 5px; font-weight: 900;">
                {{ orderDetail.shippingMethod.type }}
              </v-col>
            </v-row>
            <v-row v-if=" orderDetail.shippingMethod.box">
              <v-col cols="4" style="padding-bottom: 0; padding-top: 0;">
                <span style="font-size: 20px; font-weight: bold;">Box ID:</span>
              </v-col>
              <v-col style="padding-bottom: 0; padding-top: 5px; font-weight: 900;">
                {{ orderDetail.shippingMethod.box }}
              </v-col>
            </v-row>
            <v-row v-if="orderDetail.rmaNumber">
              <v-col cols="4" style="padding-bottom: 0; padding-top: 0;">
                <span style="font-size: 20px; font-weight: bold;">RMA Number:</span>
              </v-col>
              <v-col style="padding-bottom: 0; padding-top: 5px;">
                <v-chip color="red" dark>
                  {{ orderDetail.rmaNumber }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row style="margin-top: 20px;">
              <v-col cols="4" style="padding-bottom: 0; padding-top: 0;">
                <span style="font-size: 20px; font-weight: bold;">Shipment label:</span>
              </v-col>
              <v-col style="padding-bottom: 0; padding-top: 2px; font-weight: 900;">
                <v-btn
                  v-if="orderDetail.shippingMethod.shippingLabel"
                  small
                  color="green"
                  dark
                  @click="openLabel(orderDetail.shippingMethod.shippingLabel)"
                >
                  Open Label
                </v-btn>
                <div v-else style="padding-top: 3px; color: red;">
                  Not generated
                  <v-btn
                    v-if="!orderDetail.shippingMethod.shippingLabel && orderDetail.orderStatus === 'Dispatched'"
                    small
                    class="ml-2"
                    elevation="0"
                    color="green lighten-4"
                    @click="getShippingLabel"
                  >
                    Generate
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <div style="border: 1px solid lightgray; border-radius: 5px;" class="pa-1">
              <div style="font-size: 16px; font-weight: bold;">
                Customer contact:
              </div>
              <div class="ml-1">
                <v-icon color="blue" size="small">
                  mdi-at
                </v-icon>
                {{ orderDetail.customerEmail || 'not entered' }}
              </div>
              <div class="ml-1">
                <v-icon color="blue" size="small">
                  mdi-phone
                </v-icon>
                {{ orderDetail.customerPhone || 'not entered' }}
              </div>
            </div>
            <div style="border: 1px solid lightgray; border-radius: 5px;" class="mt-2 pa-1">
              <div style="font-size: 16px; font-weight: bold;">
                Ship to address:
              </div>
              <div class="ml-1">
                {{ orderDetail.customerName }}
              </div>
              <div class="ml-1">
                {{ orderDetail.shipToAddress.street }}
              </div>
              <div class="ml-1">
                {{ orderDetail.shipToAddress.city }}
              </div>
              <div class="ml-1">
                {{ orderDetail.shipToAddress.postCode }}
              </div>
              <div class="ml-1">
                {{ orderDetail.shipToAddress.country }}
              </div>
            </div>
          </v-col>
        </v-row>
        <div class="mt-6">
          <h2>Ordered items:</h2>
          <v-data-table
            :headers="headersOrderDetail"
            :items="orderedItems"
            :items-per-page="10"
            class="elevation-0"
          >
            <template v-slot:item.qty="{ item }">
              <v-chip>{{ item.qty }}x</v-chip>
            </template>
            <template v-slot:item.picture="{ item }">
              <div class="p-2">
                <v-img
                  :src="item.picture"
                  width="40px"
                />
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-checkbox v-model="item.dispatched" :disabled="orderDetail.orderStatus === 'Dispatched' || orderDetail.orderStatus === 'Returning' || orderDetail.orderStatus === 'Returned'" />
            </template>
          </v-data-table>
        </div>
        <div class="mt-0">
          <v-dialog
            v-model="dialogDispatchOrder"
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="green"
                :dark="orderDetail.orderStatus !== 'Dispatched' && orderDetail.orderStatus !== 'Returning' && orderDetail.orderStatus !== 'Returned'"
                :disabled="orderDetail.orderStatus === 'Dispatched' || orderDetail.orderStatus === 'Returning' || orderDetail.orderStatus === 'Returned'"
                v-bind="attrs"
                v-on="on"
              >
                Dispatch Order and generate Budbee shipping label
              </v-btn>
            </template>

            <v-card :loading="showProgress">
              <v-card-title class="text-h5 grey lighten-4">
                Dispatch order
              </v-card-title>

              <v-card-text class="mt-5">
                <div
                  v-if="orderedItems.some(item => !item.dispatched )"
                  class="red--text"
                  style="font-size: 20px; font-weight: bold;"
                >
                  Some items are not confirmed as packed!
                </div>
                <div class="mt-2">
                  <v-text-field
                    v-model="packageWeight"
                    type="number"
                    label="Package weight (g)"
                  />
                  <v-btn
                    color="light-blue"
                    :disabled="!packageWeight || loadingShipLabel"
                    dark
                    @click="getShippingLabel"
                  >
                    <span v-if="!loadingShipLabel">Generate Shipping label</span>
                    <div v-else>
                      <v-progress-circular
                        indeterminate
                        color="white"
                      />
                    </div>
                  </v-btn>
                </div>
                <div class="mt-2">
                  This action will change the order and it's items to status: 'Dispatched' or 'On its way'
                </div>
              </v-card-text>

              <v-divider />

              <v-card-actions>
                <v-btn
                  color="red"
                  text
                  :disabled="showProgress"
                  @click="dialogDispatchOrder = false"
                >
                  Cancel
                </v-btn>
                <v-spacer />
                <v-btn
                  color="green"
                  dark
                  :disabled="showProgress || loadingShipLabel || !packageWeight"
                  @click="dispatchOrder"
                >
                  Dispatch Order
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogOrderManualStatusChange"
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-2"
                color="orange"
                dark
                v-bind="attrs"
                v-on="on"
              >
                Manual order status change
              </v-btn>
            </template>

            <v-card :loading="showProgress">
              <v-card-title class="text-h5 grey lighten-4">
                Order status change
              </v-card-title>

              <v-card-text class="mt-5">
                <v-select
                  v-model="orderStatusManualChange"
                  :items="orderStatuses"
                  label="New Status"
                />
                <div class="mt-2">
                  This action will change the order status
                </div>
              </v-card-text>

              <v-divider />

              <v-card-actions>
                <v-btn
                  color="red"
                  text
                  :disabled="showProgress"
                  @click="dialogOrderManualStatusChange = false"
                >
                  Cancel
                </v-btn>
                <v-spacer />
                <v-btn
                  color="green"
                  dark
                  :disabled="showProgress || !orderStatusManualChange"
                  @click="manualStatusChange"
                >
                  Save Status
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <v-divider class="mt-10 mb-5" />
        <div v-if="orderDetail.rmaNumber" class="mt-5">
          <div style="font-size: 20px; font-weight: bold;">
            RMA Section
          </div>
          <div class="red--text" style="font-size: 20px; font-weight: bold;">
            User has requested return as per following
          </div>
          <div>
            <v-data-table
              v-if="rmaLoaded"
              :headers="headersReturnOrderDetail"
              :items="returnItems"
              :items-per-page="10"
              class="elevation-0"
            >
              <template v-slot:item.qty="{ item }">
                <v-chip>{{ item.qty }}x</v-chip>
              </template>
              <template v-slot:item.picture="{ item }">
                <div class="p-2">
                  <v-img
                    :src="item.picture"
                    width="40px"
                  />
                </div>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-checkbox v-model="item.returned" />
              </template>
            </v-data-table>
          </div>
          <v-row v-if="rmaLoaded" class="mt-1">
            <v-col cols="4">
              <div class="pa-3" style="border: 1px solid lightgray; border-radius: 5px; min-height: 300px;">
                <div class="text-decoration-underline">
                  Reason to cancel
                </div>
                {{ rmaObject.reasonToCancel }}
              </div>
            </v-col>
            <v-col>
              <div class="pa-3">
                <v-card class="pa-3" elevation="0">
                  1.
                  <v-btn
                    color="blue lighten-1"
                    :disabled="approveReturnLoading || rmaObject.status === 'Approved' || rmaObject.status === 'Closed'"
                    @click="approveReturn(rmaObject, returnItems)"
                  >
                    <span v-if="!approveReturnLoading">Approve Return</span>
                    <div v-else>
                      <v-progress-circular
                        indeterminate
                        color="black"
                      />
                    </div>
                  </v-btn>
                  <v-card-text style="color: black;">
                    <span v-if="rmaObject.status === 'Approved' || rmaObject.status === 'Closed'" style="font-weight: bold; font-size: 22px;">Already Approved!</span>
                    This action will send an email with return address and all necessary information to complete the return on users side
                  </v-card-text>
                </v-card>
                <v-card class="pa-3" elevation="0">
                  <div class="mt-10">
                    2.
                    <v-btn
                      color="red lighten-1"
                      :disabled="approveReturnLoading || rmaObject.status !== 'Approved'"
                      @click="closeReturn(rmaObject)"
                    >
                      Complete Return
                    </v-btn>
                    <v-card-text>
                      1. Don't forget to confirm items that you have receive them back<br>
                      2. This action will close and complete the return. (note. Sub cancellation and refund has to be done manually prior clicking on this button)
                    </v-card-text>
                  </div>
                  <v-card />
                </v-card>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-slide-x-transition>
    <v-alert
      v-if="showError"
      style="position: absolute; top: 25px;; left: 50%; transform: translate(-50%); z-index: 1000;"
      type="error"
      dismissible
    >
      {{ errorMessage }}
    </v-alert>
  </v-container>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'EshopOrders',
  components: {

  },
  data () {
    return {
      dayjs,
      selectedView: '1',
      dialogDispatchOrder: false,
      showError: false,
      errorMessage: '',
      pagination: {},
      orderStatusSelectorSelection: 'All',
      search: '',
      items: [],
      filteredItems: [],
      headers: [
        { text: '#', value: 'orderId' },
        { text: 'Date', value: 'createdAt' },
        { text: 'Order Status', value: 'orderStatus' },
        { text: 'Payment Status', value: 'paymentStatus' },
        { text: 'Value Paid', value: 'totalPaid' },
        { text: 'Name & Email', value: 'customerName' },
        // { text: 'Email', value: 'customerEmail' },
        { text: 'Address To Ship', value: 'shipToAddress' },
        { text: 'Delivery Method', value: 'shippingMethod' },
        { text: 'RMA', value: 'rmaNumber' }
      ],
      orderedItems: [],
      headersOrderDetail: [
        { text: '#', value: 'id' },
        { text: 'SKU', value: 'sku' },
        { text: 'Item Name', value: 'name' },
        { text: 'Picture', value: 'picture' },
        { text: 'QTY', value: 'qty' },
        { text: 'Packed?', value: 'actions', sortable: false }
      ],
      headersReturnOrderDetail: [
        { text: '#', value: 'id' },
        { text: 'SKU', value: 'sku' },
        { text: 'Item Name', value: 'name' },
        { text: 'Picture', value: 'picture' },
        { text: 'QTY', value: 'qty' },
        { text: 'Returned?', value: 'actions', sortable: false }
      ],
      footerProps: {
        'items-per-page-options': [30, 50, 100]
      },
      selectStatusOfOrder: [
        { text: 'All Orders', value: 'All' },
        { text: 'New Orders', value: 'Preparing' },
        { text: 'Created - Not completed', value: 'Created' },
        { text: 'Dispatched', value: 'Dispatched' },
        { text: 'Completed', value: 'Completed' },
        { text: 'Returned', value: 'Returned' },
        { text: 'Cancelled', value: 'Cancelled' }
      ],
      showProgress: false,
      isMounted: false,
      orderDetail: null,
      orderStatuses: [
        'Preparing',
        'Dispatched',
        'Delayed',
        'Returned',
        'Cancelled'
      ],
      dialogOrderManualStatusChange: false,
      orderStatusManualChange: '',
      rmaObject: null,
      returnItems: [],
      rmaLoaded: false,
      packageWeight: 200,
      loadingShipLabel: false,
      approveReturnLoading: false,
      shippingLabelGenerated: false
    }
  },
  computed: {
  },
  watch: {
    search: function (val) {
      if (val !== '') {
        this.orderStatusSelectorSelection = 'All'
      }
    },
    orderStatusSelectorSelection: function (value) {
      if (value === 'All') {
        this.filteredItems = this.items
      } else {
        this.filteredItems = this.items.filter(item => item.orderStatus === value)
      }
    }
  },
  mounted () {
    this.pullEshopData()
    this.isMounted = true
  },
  methods: {
    itemRowBackground (item) {
      let css = 'default'
      if (item.orderStatus === 'Preparing') {
        css = 'light-purple-background'
      } else if (item.orderStatus === 'Created') {
        css = 'light-gray-background'
      } else if (item.orderStatus === 'Dispatched' || item.orderStatus === 'Completed') {
        css = 'light-green-background'
      } else if (item.orderStatus === 'Returned' || item.orderStatus === 'Cancelled') {
        css = 'light-red-background'
      }
      return css
    },
    pullEshopData () {
      this.showProgress = true
      this.$store.dispatch('pullEshopOrders').then(resp => {
        if (resp) {
          this.items = resp.data
          this.filteredItems = resp.data
          this.showProgress = false
          if (this.orderDetail !== null) {
            const orderId = this.orderDetail.orderId
            this.orderDetail = this.items.filter(item => item.orderId === orderId)[0]
          }
        }
        this.showProgress = false
      })
    },
    openOrderDetail (item) {
      this.showProgress = true
      this.selectedView = '2'
      this.orderDetail = item
      this.orderedItems = item.orderItems

      if (item.rmaNumber) {
        this.$store.dispatch('getRMADetails', { rmaNumber: item.rmaNumber })
          .then((resp) => {
            this.rmaObject = resp.data
            const returnItems = resp.data.returnItems
            returnItems.forEach(item => {
              const returnItemSku = item.itemSKU
              const i = this.orderedItems.filter(item => item.sku === returnItemSku)[0]
              this.returnItems.push(i)
            })
            this.showProgress = false
            this.rmaLoaded = true
          })
          .catch((error) => {
            this.showProgress = false
            this.showError = true
            this.errorMessage = error
            setTimeout(() => {
              this.errorMessage = ''
              this.showError = false
            }, 5000)
          })
      }
      this.showProgress = false
    },
    dispatchOrder () {
      this.showProgress = true
      const object = {
        type: 'dispatchOrder',
        orderId: this.orderDetail.orderId,
        status: 'Dispatched',
        orderItems: this.orderedItems
      }
      this.editOrder(object)
    },
    manualStatusChange () {
      this.showProgress = true
      const object = {
        type: 'manualStatusChange',
        orderId: this.orderDetail.orderId,
        status: this.orderStatusManualChange
      }
      this.editOrder(object)
    },
    editOrder (data) {
      this.$store.dispatch('editShopOrder', data)
        .then(() => {
          this.showProgress = true
          this.dialogDispatchOrder = false
          this.dialogOrderManualStatusChange = false
          this.pullEshopData()
        })
        .catch((error) => {
          this.showProgress = false
          this.showError = true
          this.dialogDispatchOrder = false
          this.dialogOrderManualStatusChange = false
          this.orderStatusManualChange = ''
          this.errorMessage = error
          setTimeout(() => {
            this.errorMessage = ''
            this.showError = false
          }, 5000)
        })
    },
    goBack () {
      this.selectedView = '1'
      this.rmaObject = null
      this.returnItems = []
      this.orderDetail = null
      this.rmaLoaded = false
      this.orderedItems = []
      this.pullEshopData()
    },
    getShippingLabel () {
      this.loadingShipLabel = true
      const order = this.orderDetail
      if (!order) {
        this.errorMessage = 'That did not work :( sorry'
        this.showError = true
        this.loadingShipLabel = false
        setTimeout(() => {
          this.errorMessage = ''
          this.showError = false
        }, 5000)
      }
      const data = {
        customerName: order.customerName,
        customerEmail: order.customerEmail,
        customerPhone: order.customerPhone,
        street: order.shipToAddress.street,
        postCode: order.shipToAddress.postCode,
        city: order.shipToAddress.city,
        weight: Number(this.packageWeight),
        orderId: order.orderId,
        type: order.shippingMethod.type,
        selectedBox: order.shippingMethod.box
      }
      this.$store.dispatch('getBudbeeLabel', data)
        .then(resp => {
          const label = resp.data[0].label
          this.shippingLabelGenerated = true
          this.orderDetail.shippingMethod.shippingLabel = label
          this.loadingShipLabel = false
        })
        .catch(error => {
          this.errorMessage = error
          this.showError = true
          setTimeout(() => {
            this.errorMessage = ''
            this.showError = false
          }, 5000)
        })
    },
    openLabel (label) {
      window.open(label)
    },
    statusColor (type) {
      let color = ''
      if (type === 'Preparing') {
        color = 'purple'
      } else if (type === 'Dispatched') {
        color = 'green'
      } else if (type === 'Returning') {
        color = 'red'
      } else if (type === 'Completed') {
        color = 'green'
      } else if (type === 'Created') {
        color = 'grey'
      } else if (type === 'Returned') {
        color = 'red lighten-2'
      } else if (type === 'Cancelled') {
        color = 'red lighten-2'
      } else {
        color = 'grey'
      }
      return color
    },
    approveReturn (rmaObject, returns) {
      this.approveReturnLoading = true
      const returnItemsObj = []
      returns.forEach(item => {
        const object = {
          name: item.name,
          qty: item.qty
        }
        returnItemsObj.push(object)
      })
      const customerEmail = rmaObject.customerDetails[0].email
      const customerName = rmaObject.customerDetails[0].name
      const rmaNumber = rmaObject.rmaNumber

      this.$store.dispatch('processReturn', { rmaNumber, type: 'Approve', customerName, customerEmail, returnItems: returnItemsObj })
        .then(resp => {
          this.approveReturnLoading = false
          this.rmaObject.status = 'Approved'
        })
    },
    closeReturn (rmaObject) {
      this.approveReturnLoading = true
      const orderId = rmaObject.orderObject.orderId
      const rmaNumber = rmaObject.rmaNumber
      this.$store.dispatch('processReturn', { rmaNumber, orderId, type: 'Close' })
        .then(resp => {
          this.approveReturnLoading = false
          this.rmaObject.status = 'Closed'
        })
    }
  }
}
</script>

<style lang="scss">
.light-purple-background {
  background-color: #F3E5F5;
}

.light-gray-background {
    background-color: #BDBDBD;
}

.light-green-background {
    background-color: #E8F5E9;
}

.light-red-background {
    background-color: #FFEBEE;
}
</style>
